<!--
 * @Description: 新增、修改运费模板
 * @Author: 琢磨先生
 * @Date: 2022-06-08 15:48:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-08 17:00:45
-->

<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    direction="rtl"
    size="900px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="模板名称" prop="name">
        <el-col :span="10">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="包邮配送区域">
        <el-checkbox-group v-model="form.codes">
          <div class="grid">
            <el-checkbox
              :label="item.code"
              v-for="item in provinceList"
              :key="item.code"
              @change="exemptionChange"
              >{{ item.name }}</el-checkbox
            >
          </div>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="不配送区域">
        <el-tag
          :key="item.code"
          v-for="item in noDeliveryList"
          style="margin-right: 10px; margin-top: 10px"
          >{{ item.name }}</el-tag
        >
      </el-form-item>
      <el-form-item label="默认">
        <el-switch v-model="form.is_default"></el-switch>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
          :disabled="loading"
          :loading="loading"
          >确定</el-button
        >
        <el-button @click="cancelClick" :disabled="loading">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      drawer: false,
      title: "",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
      //省份
      provinceList: [],
      //不配送省份
      noDeliveryList: [],
    };
  },
  props: ["item", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.title = "新增运费模板";
          this.form = Object.assign({}, this.item);
          this.form.codes = [];
          if (this.item.id) {
            this.title = "修改运费模板";
            this.form.codes = this.item.contents
              .filter((x) => x.type == 0)
              .map((x) => {
                return x.region_code;
              });
          } else {
            this.provinceList.forEach((x) => {
              this.form.codes.push(x.code);
            });
          }
        }
      },
    },
  },
  created() {
    this.loadProvince();
  },
  methods: {
    /**
     * 加载所有省份
     */
    loadProvince() {
      this.$http.get("admin/v1/common/region/provinces").then((res) => {
        if (res.code == 0) {
          this.provinceList = res.data;
        }
      });
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.form.contents = [];
          this.provinceList.forEach((item) => {
            if (this.form.codes.findIndex((x) => x == item.code) > -1) {
              this.form.contents.push({
                region_code: item.code,
                type: 0,
              });
            } else {
              this.form.contents.push({
                region_code: item.code,
                type: 2,
              });
            }
          });
          this.$http
            .post("/admin/v1/carriage/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
    /**
     * 包邮选择变动
     */
    exemptionChange() {
      this.noDeliveryList = [];
      this.provinceList.forEach((item) => {
        if (this.form.codes.findIndex((o) => o == item.code) == -1) {
          this.noDeliveryList.push({ ...item });
        }
      });
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
</style>